"use client";
import {FC, useState, MouseEvent} from "react";
import LogoComponent from "./Logo.component";
import ModeToggle from "@/components/ui/ModeToggle";
import {useUser} from "@clerk/nextjs";
import {cn} from "@/lib/utils";
import Link from "next/link";

type IsAdmin = boolean;

const HeaderComponent: FC = () => {
  const [click, setClick] = useState(false);
  const {isLoaded, user} = useUser() || {isLoaded: false, user: undefined};
  const {organizationMemberships} = user ?? {organizationMemberships: []};
  const isAdmin =
    organizationMemberships &&
    organizationMemberships.length > 0 &&
    organizationMemberships[0]?.role === "org:admin";

  const toggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setClick(!click);
  };
  return (
    <header className="w-full p-4 px-5 sm:px-10 flex items-center justify-between">
      {user && isLoaded && isAdmin && (
        <>
          <p>
            Welcome, {user.fullName} Role: {isAdmin ? "Admin" : "User"}
          </p>
        </>
      )}
      <button
        className="inline-block sm:hidden z-50"
        onClick={toggle}
        aria-label="Hamburger Menu"
      >
        <div className="w-6 cursor-pointer transition-all ease duration-300">
          <div className="relative">
            <span
              className="absolute top-0 inline-block w-full h-0.5 bg-dark dark:bg-light rounded transition-all ease duration-200 whitespace-nowrap"
              style={{
                transform: click
                  ? "rotate(-45deg) translateY(0)"
                  : "rotate(0deg) translateY(6px)",
              }}
            ></span>
            <span
              className="absolute top-0 inline-block w-full h-0.5 bg-dark dark:bg-light rounded transition-all ease duration-200 whitespace-nowrap"
              style={{
                opacity: click ? 0 : 1,
              }}
            ></span>
            <span
              className="absolute top-0 inline-block w-full h-0.5 bg-dark dark:bg-light rounded transition-all ease duration-200 whitespace-nowrap"
              style={{
                transform: click
                  ? "rotate(45deg) translateY(0)"
                  : "rotate(0deg) translateY(-6px)",
              }}
            ></span>
          </div>
        </div>
      </button>

      <nav
        className="w-max py-1 px-6 sm:px-8 border border-solid border-dark rounded-full font-medium capitalize items-center flex sm:hidden fixed top-6 right-1/2 translate-x-1/2 bg-light/80 backdrop-blur-sm z-50 transition-all ease duration-300"
        style={{top: click ? "1rem" : "-5rem"}}
      >
        <Link
          href="/"
          className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
        >
          Home
        </Link>
        {isAdmin && (
          <>
            <Link
              href="/dashboard"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Dashboard
            </Link>
            <Link
              href="/dashboard2"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Dashboard 2
            </Link>
            <Link
              href="/clerkDashboard"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Clerk Dashboard
            </Link>
          </>
        )}
        <span className="px-7 font-bold dark:font-semibold text-lg md:text-xl text-AIColor">
          Embrace AI
        </span>
        <ModeToggle />
      </nav>
      <nav className="w-max py-3 px-8 border border-solid border-dark rounded-full font-medium capitalize items-center hidden sm:flex fixed top-6 right-1/2 translate-x-1/2 bg-light/80 backdrop-blur-sm z-50">
        <Link
          href="/"
          className="mr-2 text-accentAlaa dark:text-dark hover:bg-accentDarkAlaa"
        >
          Home
        </Link>
        {isAdmin && (
          <>
            <Link
              href="/dashboard"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Dashboard
            </Link>
            <Link
              href="/dashboard2"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Dashboard 2
            </Link>
            <Link
              href="/clerkDashboard"
              className="mr-2 text-accentAlaa dark:text-accentDarkAlaa hover:bg-primary/90"
            >
              Clerk Dashboard
            </Link>
          </>
        )}
        <span className="px-7 font-bold dark:font-semibold text-lg md:text-xl text-AIColor">
          Embrace AI
        </span>
        <ModeToggle />
      </nav>
      <div className="hidden sm:flex items-center">
        <LogoComponent />
      </div>
    </header>
  );
};

export default HeaderComponent;
