import Link from "next/link";
import {FC} from "react";
import Image from "next/image";
import logoImage from "@/../public/logos/logo-png.png";

const LogoComponent: FC = () => {
  return (
    <Link
      href="/"
      passHref
      className="flex items-center dark:text-accentAlaa text-accentDarkAlaa"
    >
      <div className="w-12 md:w-16 overflow-hidden border border-solid border-dark dark:border-gray mr-0 md:mr-0">
        <Image
          src={logoImage}
          alt="PDFChatMate logo"
          width={1024}
          height={1024}
          sizes="33vw"
          className="w-full h-auto"
          priority
        />
      </div>
      <span className="px-2 font-bold dark:font-semibold text-lg md:text-xl">
        PDFChatMate
      </span>
    </Link>
  );
};

export default LogoComponent;
