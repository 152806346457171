"use client";
import Link from "next/link";
import {FC} from "react";

const FooterComponent: FC<any> = () => {
  return (
    <footer className="mt-16 rounded-2xl bg-accentAlaa dark:bg-accentDark/90 m-2 sm:m-10 flex flex-col items-center text-light dark:text-dark">
      <div className="flex items-center ml-6 mt-8">
        <Link
          href="/contact"
          className="inline-block w-15 h-10 mr-6 hover:scale-125 transition-all ease duration-200"
        >
          Contact
        </Link>
        <Link
          href="/policy"
          className="inline-block w-15 h-10 mr-6 hover:scale-125 transition-all ease duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms"
          className="inline-block w-15 h-10 mr-6 hover:scale-125 transition-all ease duration-200"
        >
          Terms and Conditions
        </Link>
        {/*         <Link
          href="/faq"
          className="inline-block w-15 h-10 mr-6 hover:scale-125 transition-all ease duration-200"
        >
          FAQ
        </Link> */}
        <Link
          href="/imprints"
          className="inline-block w-15 h-10 mr-6 hover:scale-125 transition-all ease duration-200"
        >
          Imprints
        </Link>
      </div>
      <div className="w-full mt-16 md:mt-24 relative font-medium border-t border-solid border-light py-6 px-8 flex flex-col md:flex-row items-center justify-between">
        <span className="text-center">
          &copy;2024 InnoVertex Solutions, Alaa Hammam. All rights reserved.
        </span>
      </div>
      <Link href="/sitemap.xml" className="text-center underline my-4 md:my-0">
        sitemap.xml
      </Link>
    </footer>
  );
};

export default FooterComponent;
